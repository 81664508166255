<template>
    <div class='grid mt-4'>
        <div class='col-12 surface-ground'>
            <h4>Conclusioni</h4>
            <DataTable
                class='p-datatable-gridlines p-datatable-sm'
                :rowHover='false'
                dataKey='id'
                :value='rows'
            >
                <Column field='title' header='' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #body='slotProps'>
                        <div
                            :class='{[slotProps.data.bg]: true}'
                            class='flex align-items-center justify-content-center border-round'
                            style='width:1.5rem;height:1.5rem'>
                            <i :class='{[slotProps.data.color]: true}'
                               class='pi text-md'>{{ slotProps.data.title }}</i>
                        </div>
                    </template>
                </Column>
                <Column field='partecipants' header='V' :sortable='true' style='min-width:2rem'
                        class='text-center'>
                </Column>
                <Column field='subs' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Numero totale di subscriptions"'></i> Subs
                    </template>
                    <template #body='slotProps'>
                        <div
                            :class='{[tpl.win.winner]: slotProps.data.wins.subs, [tpl.win.looser]: !slotProps.data.wins.subs}'>
                            {{ slotProps.data.subs }}
                            <small v-if='slotProps.data.delta && slotProps.data.delta.subs' class='delta'>
                                ({{ slotProps.data.delta.subs }}%)</small>
                        </div>
                    </template>
                </Column>
                <Column field='subs_CR_s' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Numero totale di subscriptions / Signups"'></i> CRu
                    </template>
                    <template #body='slotProps'>
                        <div
                            :class='{[tpl.win.winner]: slotProps.data.wins.subs_CR_s, [tpl.win.looser]: !slotProps.data.wins.subs_CR_s}'>
                            {{ slotProps.data.subs_CR_s }}%
                            <small v-if='slotProps.data.delta && slotProps.data.delta.subs_CR_s' class='delta'>
                                ({{ slotProps.data.delta.subs_CR_s }}%)</small>
                        </div>
                    </template>
                </Column>
                <Column field='subs_CR_v' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Numero totale di subscriptions / Visitors"'></i> CRv
                    </template>
                    <template #body='slotProps'>
                        <div
                            :class='{[tpl.win.winner]: slotProps.data.wins.subs_CR_v, [tpl.win.looser]: !slotProps.data.wins.subs_CR_v}'>
                            {{ slotProps.data.subs_CR_v }}%
                            <small v-if='slotProps.data.delta && slotProps.data.delta.subs_CR_v' class='delta'>
                                ({{ slotProps.data.delta.subs_CR_v }}%)</small>
                        </div>
                    </template>
                </Column>

                <Column field='active_subs' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Subscriptions ATTIVE"'></i> Sub Att
                    </template>
                    <template #body='slotProps'>
                        <div
                            :class='{[tpl.win.winner]: slotProps.data.wins.active_subs, [tpl.win.looser]: !slotProps.data.wins.active_subs}'>
                            {{ slotProps.data.active_subs }}
                            <small v-if='slotProps.data.delta && slotProps.data.delta.active_subs' class='delta'>
                                ({{ slotProps.data.delta.active_subs }}%)</small>
                        </div>
                    </template>
                </Column>
                <Column field='active_sub_CR_s' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Subscriptions attivate / Signups"'></i> CRu
                    </template>
                    <template #body='slotProps'>
                        <div
                            :class='{[tpl.win.winner]: slotProps.data.wins.active_sub_CR_s, [tpl.win.looser]: !slotProps.data.wins.active_sub_CR_s}'>
                            {{ slotProps.data.active_sub_CR_s }}%
                            <small v-if='slotProps.data.delta && slotProps.data.delta.active_sub_CR_s' class='delta'>
                                ({{ slotProps.data.delta.active_sub_CR_s }}%)</small>
                        </div>
                    </template>
                </Column>
                <Column field='active_sub_CR_v' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Subscriptions attivate / Visitors"'></i> CRv
                    </template>
                    <template #body='slotProps'>
                        <div
                            :class='{[tpl.win.winner]: slotProps.data.wins.active_sub_CR_v, [tpl.win.looser]: !slotProps.data.wins.active_sub_CR_v}'>
                            {{ slotProps.data.active_sub_CR_v }}%
                            <small v-if='slotProps.data.delta && slotProps.data.delta.active_sub_CR_v' class='delta'>
                                ({{ slotProps.data.delta.active_sub_CR_v }}%)</small>
                        </div>
                    </template>
                </Column>

                <Column field='free_trials' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Subscriptions con Free Trial totali"'></i> Free T
                    </template>
                    <template #body='slotProps'>
                        <div
                            :class='{[tpl.win.winner]: slotProps.data.wins.free_trials, [tpl.win.looser]: !slotProps.data.wins.free_trials}'>
                            {{ slotProps.data.free_trials }}
                            <small v-if='slotProps.data.delta && slotProps.data.delta.free_trials' class='delta'>
                                ({{ slotProps.data.delta.free_trials }}%)</small>
                        </div>
                    </template>
                </Column>
                <Column field='ft2paid' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Subscriptions con Free Trial attivate"'></i> FT2P
                    </template>
                    <template #body='slotProps'>
                        <div
                            :class='{[tpl.win.winner]: slotProps.data.wins.ft2paid, [tpl.win.looser]: !slotProps.data.wins.ft2paid}'>
                            {{ slotProps.data.ft2paid }}
                            <small v-if='slotProps.data.delta && slotProps.data.delta.ft2paid' class='delta'>
                                ({{ slotProps.data.delta.ft2paid }}%)</small>
                        </div>
                    </template>
                </Column>
                <Column field='ft2paid_CR' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"CR Free Trials to Paid"'></i> CR F2P
                    </template>
                    <template #body='slotProps'>
                        <div
                            :class='{[tpl.win.winner]: slotProps.data.wins.ft2paid_CR, [tpl.win.looser]: !slotProps.data.wins.ft2paid_CR}'>
                            {{ slotProps.data.ft2paid_CR }}%
                            <small v-if='slotProps.data.delta && slotProps.data.delta.ft2paid_CR' class='delta'>
                                ({{ slotProps.data.delta.ft2paid_CR }}%)</small>
                        </div>
                    </template>
                </Column>

                <Column field='ltv' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"LTV per le subscription attive"'></i> LTV
                    </template>
                    <template #body='slotProps'>
                        <div
                            :class='{[tpl.win.winner]: slotProps.data.wins.ltv, [tpl.win.looser]: !slotProps.data.wins.ltv}'>
                            {{ slotProps.data.ltv }}€
                            <small v-if='slotProps.data.delta && slotProps.data.delta.ltv' class='delta'>
                                ({{ slotProps.data.delta.ltv }}%)</small>
                        </div>
                    </template>
                </Column>
                <Column field='ltv_x_user' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Media LTV per signup"'></i> LTVu
                    </template>
                    <template #body='slotProps'>
                        <div
                            :class='{[tpl.win.winner]: slotProps.data.wins.ltv_x_user, [tpl.win.looser]: !slotProps.data.wins.ltv_x_user}'>
                            {{ slotProps.data.ltv_x_user }}€
                            <small v-if='slotProps.data.delta && slotProps.data.delta.ltv_x_user' class='delta'>
                                ({{ slotProps.data.delta.ltv_x_user }}%)</small>
                        </div>
                    </template>
                </Column>
                <Column field='ltv_x_part' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Media LTV per visitor"'></i> LTVv
                    </template>
                    <template #body='slotProps'>
                        <div
                            :class='{[tpl.win.winner]: slotProps.data.wins.ltv_x_part, [tpl.win.looser]: !slotProps.data.wins.ltv_x_part}'>
                            {{ slotProps.data.ltv_x_part }}€
                            <small v-if='slotProps.data.delta && slotProps.data.delta.ltv_x_part' class='delta'>
                                ({{ slotProps.data.delta.ltv_x_part }}%)</small>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        rows: {
            type: Array,
            required: true,
            default(){
                return [];
            }
        },
        tpl: {
            type: Object,
            default() {
                return {};
            },
            required: true
        }
    }
}
</script>
