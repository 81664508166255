<template>
    <h4>Checkouts</h4>
    <DataTable
        class='p-datatable-gridlines p-datatable-sm'
        :rowHover='false'
        dataKey='id'
        :value='rows'
    >
        <Column field='title' header='' :sortable='true' style='min-width:2rem' class='text-center'>
            <template #body='slotProps'>
                <div
                    :class='{[slotProps.data.bg]: true}'
                    class='flex align-items-center justify-content-center border-round'
                    style='width:1.5rem;height:1.5rem'>
                    <i :class='{[slotProps.data.color]: true}'
                       class='pi text-md'>{{ slotProps.data.title }}</i>
                </div>
            </template>
        </Column>

        <Column field='viewed' header='Viewes' :sortable='true' style='min-width:2rem'
                class='text-center'>
        </Column>
        <Column field='viewed_users' header='Us Viewed' :sortable='true' style='min-width:2rem'
                class='text-center'>
        </Column>
        <Column field='crv' :sortable='true' style='min-width:2rem' class='text-center'>
            <template #header>
                <i class='pi pi-info-circle mr-1 text-blue-600'
                   v-tooltip.top='"Utenti che hanno visto un checkout / Signups"'></i> CR
            </template>
            <template #body='slotProps'>
                <div
                    :class='{[tpl.win.winner]: slotProps.data.crvWin, [tpl.win.looser]: !slotProps.data.crvWin}'>
                    {{ slotProps.data.crv }}%
                    <small v-if='slotProps.data.crv_delta' class='delta'>
                        ({{ slotProps.data.crv_delta }}%)</small>
                </div>
            </template>
        </Column>

        <Column field='started' header='Started' :sortable='true' style='min-width:2rem'
                class='text-center'>
        </Column>
        <Column field='started_users' header='Us Started' :sortable='true' style='min-width:2rem'
                class='text-center'>
        </Column>
        <Column field='crs' :sortable='true' style='min-width:2rem' class='text-center'>
            <template #header>
                <i class='pi pi-info-circle mr-1 text-blue-600'
                   v-tooltip.top='"Utenti che hanno iniziato un checkout / Signups"'></i> CR
            </template>
            <template #body='slotProps'>
                <div
                    :class='{[tpl.win.winner]: slotProps.data.crsWin, [tpl.win.looser]: !slotProps.data.crsWin}'>
                    {{ slotProps.data.crs }}%
                    <small v-if='slotProps.data.crs_delta' class='delta'>
                        ({{ slotProps.data.crs_delta }}%)</small>
                </div>
            </template>
        </Column>

        <Column field='completed_users' header='Us Completed' :sortable='true' style='min-width:2rem'
                class='text-center'>
        </Column>
        <Column field='crc' :sortable='true' style='min-width:2rem' class='text-center'>
            <template #header>
                <i class='pi pi-info-circle mr-1 text-blue-600'
                   v-tooltip.top='"Utenti che hanno completato un checkout / Signups"'></i> CR
            </template>
            <template #body='slotProps'>
                <div
                    :class='{[tpl.win.winner]: slotProps.data.crcWin, [tpl.win.looser]: !slotProps.data.crcWin}'>
                    {{ slotProps.data.crc }}%
                    <small v-if='slotProps.data.crc_delta' class='delta'>
                        ({{ slotProps.data.crc_delta }}%)</small>
                </div>
            </template>
        </Column>
    </DataTable>
</template>

<script>
export default {
    props: {
        rows: {
            type: Array,
            required: true,
            default(){
                return [];
            }
        },
        tpl: {
            type: Object,
            default() {
                return {};
            },
            required: true
        }
    }
}
</script>
