<template>
    <div class='mt-4'>
        <h4>Content engagement</h4>
        <DataTable
            class='p-datatable-gridlines p-datatable-sm'
            :rowHover='false'
            dataKey='id'
            :value='rows'
        >
            <Column field='title' header='' :sortable='true' style='min-width:2rem' class='text-center'>
                <template #body='slotProps'>
                    <div
                        :class='{[slotProps.data.bg]: true}'
                        class='flex align-items-center justify-content-center border-round'
                        style='width:1.5rem;height:1.5rem'>
                        <i :class='{[slotProps.data.color]: true}'
                           class='pi text-md'>{{ slotProps.data.title }}</i>
                    </div>
                </template>
            </Column>

            <Column field='started' header='Libri iniziati' :sortable='true' style='min-width:2rem'
                    class='text-center'>
            </Column>
            <Column field='started_users' header='Ut' :sortable='true' style='min-width:2rem'
                    class='text-center'>
            </Column>
            <Column field='spu' :sortable='true' style='min-width:2rem' class='text-center'>
                <template #header>
                    <i class='pi pi-info-circle mr-1 text-blue-600'
                       v-tooltip.top='"Libri iniziati / Utenti che hanno iniziato un libro"'></i> Media
                </template>
                <template #body='slotProps'>
                    <div
                        :class='{[tpl.win.winner]: slotProps.data.bsWin, [tpl.win.looser]: !slotProps.data.bsWin}'>
                        {{ slotProps.data.spu }}
                        <small v-if='slotProps.data.spu_delta' class='delta'>
                            ({{ slotProps.data.spu_delta }}%)</small>
                    </div>
                </template>
            </Column>
            <Column field='crs' :sortable='true' style='min-width:2rem' class='text-center'>
                <template #header>
                    <i class='pi pi-info-circle mr-1 text-blue-600'
                       v-tooltip.top='"Utenti che hanno iniziato un libro / Signups"'></i> CR
                </template>
                <template #body='slotProps'>
                    <div
                        :class='{[tpl.win.winner]: slotProps.data.crsWin, [tpl.win.looser]: !slotProps.data.crsWin}'>
                        {{ slotProps.data.crs }}%
                        <small v-if='slotProps.data.crs_delta' class='delta'>
                            ({{ slotProps.data.crs_delta }}%)</small>
                    </div>
                </template>
            </Column>

            <Column field='completed' header='Libri finiti' :sortable='true' style='min-width:2rem'
                    class='text-center'>
            </Column>
            <Column field='completed_users' header='Ut' :sortable='true' style='min-width:2rem'
                    class='text-center'>
            </Column>
            <Column field='cpu' :sortable='true' style='min-width:2rem' class='text-center'>
                <template #header>
                    <i class='pi pi-info-circle mr-1 text-blue-600'
                       v-tooltip.top='"Libri finiti / Utenti che hanno finito un libro"'></i> Media
                </template>
                <template #body='slotProps'>
                    <div
                        :class='{[tpl.win.winner]: slotProps.data.cpWin, [tpl.win.looser]: !slotProps.data.cpWin}'>
                        {{ slotProps.data.cpu }}
                        <small v-if='slotProps.data.cpu_delta' class='delta'>
                            ({{ slotProps.data.cpu_delta }}%)</small>
                    </div>
                </template>
            </Column>
            <Column field='crc' :sortable='true' style='min-width:2rem' class='text-center'>
                <template #header>
                    <i class='pi pi-info-circle mr-1 text-blue-600'
                       v-tooltip.top='"Utenti che hanno finito un libro / Signups"'></i> CR
                </template>
                <template #body='slotProps'>
                    <div
                        :class='{[tpl.win.winner]: slotProps.data.crcWin, [tpl.win.looser]: !slotProps.data.crcWin}'>
                        {{ slotProps.data.crc }}%
                        <small v-if='slotProps.data.crc_delta' class='delta'>
                            ({{ slotProps.data.crc_delta }}%)</small>
                    </div>
                </template>
            </Column>
            <Column field='favourites' header='Libri libreria' :sortable='true' style='min-width:2rem'
                    class='text-center'>
            </Column>
            <Column field='favourites_users' header='Ut' :sortable='true' style='min-width:2rem'
                    class='text-center'>
            </Column>
            <Column field='bpu' :sortable='true' style='min-width:2rem' class='text-center'>
                <template #header>
                    <i class='pi pi-info-circle mr-1 text-blue-600'
                       v-tooltip.top='"Libri libreria / Utenti che hanno messo un libro in libreria"'></i>
                    Media
                </template>
                <template #body='slotProps'>
                    <div
                        :class='{[tpl.win.winner]: slotProps.data.bpuWin, [tpl.win.looser]: !slotProps.data.bpuWin}'>
                        {{ slotProps.data.bpu }}
                        <small v-if='slotProps.data.bpu_delta' class='delta'>
                            ({{ slotProps.data.bpu_delta }}%)</small>
                    </div>
                </template>
            </Column>
            <Column field='crb' :sortable='true' style='min-width:2rem' class='text-center'>
                <template #header>
                    <i class='pi pi-info-circle mr-1 text-blue-600'
                       v-tooltip.top='"Utenti che hanno messo un libro in libreria / Signups"'></i> CR
                </template>
                <template #body='slotProps'>
                    <div
                        :class='{[tpl.win.winner]: slotProps.data.crbWin, [tpl.win.looser]: !slotProps.data.crbWin}'>
                        {{ slotProps.data.crb }}%
                        <small v-if='slotProps.data.crb_delta' class='delta'>
                            ({{ slotProps.data.crb_delta }}%)</small>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>


<script>
export default {
    props: {
        rows: {
            type: Array,
            required: true,
            default(){
                return [];
            }
        },
        tpl: {
            type: Object,
            default() {
                return {};
            },
            required: true
        }
    }
}
</script>
