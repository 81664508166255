<template>
    <div class='grid mt-4'>
        <div class='col-12 surface-ground'>
            <h4>Funnel</h4>
            <DataTable
                class='p-datatable-gridlines p-datatable-sm'
                :rowHover='false'
                dataKey='id'
                :value='rows'
            >
                <Column field='title' header='' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #body='slotProps'>
                        <div
                            :class='{[slotProps.data.bg]: true}'
                            class='flex align-items-center justify-content-center border-round'
                            style='width:1.5rem;height:1.5rem'>
                            <i :class='{[slotProps.data.color]: true}'
                               class='pi text-md'>{{ slotProps.data.title }}</i>
                        </div>
                    </template>
                </Column>
                <Column field='partecipants' header='V' :sortable='true' style='min-width:2rem'
                        class='text-center'>
                </Column>
                <Column field='signups' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Signups"'></i> Ut
                    </template>
                </Column>
                <Column field='CR_signups' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Signups / Downloads"'></i> CR
                    </template>
                    <template #body='slotProps'>
                        <div
                            :class='{[tpl.win.winner]: slotProps.data.wins.CR_signups, [tpl.win.looser]: !slotProps.data.wins.CR_signups}'>
                            {{ slotProps.data.CR_signups }}%
                            <small v-if='slotProps.data.delta && slotProps.data.delta.CR_signups' class='delta'>
                                ({{ slotProps.data.delta.CR_signups }}%)</small>
                        </div>
                    </template>
                </Column>
                <Column field='checkout_started' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Checkout Started"'></i> CoS
                    </template>
                </Column>

                <Column field='CR_co_started' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Utenti con checkout started / Signups"'></i> CR
                    </template>
                    <template #body='slotProps'>
                        <div>
                            {{ slotProps.data.CR_co_started }}%
                            <small v-if='slotProps.data.delta && slotProps.data.delta.CR_co_started'
                                   :class='smallClass(slotProps.data.delta.CR_co_started)'>
                                ({{ slotProps.data.delta.CR_co_started }}%)</small>
                        </div>
                    </template>
                </Column>
                <Column field='CR_co_started_v' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Utenti con checkout started / Downloads"'></i> CrT
                    </template>
                    <template #body='slotProps'>
                        <div
                            :class='{[tpl.win.winner]: slotProps.data.wins.CR_co_started_v, [tpl.win.looser]: !slotProps.data.wins.CR_co_started_v}'>
                            {{ slotProps.data.CR_co_started_v }}%
                            <small v-if='slotProps.data.delta && slotProps.data.delta.CR_co_started_v' class='delta'>
                                ({{ slotProps.data.delta.CR_co_started_v }}%)</small>
                        </div>
                    </template>
                </Column>


                <Column field='subs' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Subscriptions"'></i> Subs
                    </template>
                    <template #body='slotProps'>
                        <div class='flex justify-content-center flex-wrap'>
                            <span class='flex align-items-center justify-content-center'>{{slotProps.data.subs}}</span>
                            <span  class='ml-2 flex align-items-start justify-content-center flex-column'>
                                <small><strong>FT</strong>: {{slotProps.data.subs_FT}}</small>
                                <small><strong>NFT</strong>: {{slotProps.data.subs_NFT}}</small>
                            </span>
                        </div>
                    </template>
                </Column>

                <Column field='CR_subs_co' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Subscriptions / Utenti con checkout started"'></i> CR
                    </template>
                    <template #body='slotProps'>
                        <div>
                            {{ slotProps.data.CR_subs_co }}%
                            <small v-if='slotProps.data.delta && slotProps.data.delta.CR_subs_co'
                                   :class='smallClass(slotProps.data.delta.CR_subs_co)'>
                                ({{ slotProps.data.delta.CR_subs_co }}%)</small>
                        </div>
                    </template>
                </Column>

                <Column field='CR_subs_v' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Subscriptions / Downloads"'></i> CrT
                    </template>
                    <template #body='slotProps'>
                        <div
                            :class='{[tpl.win.winner]: slotProps.data.wins.CR_subs_v, [tpl.win.looser]: !slotProps.data.wins.CR_subs_v}'>
                            {{ slotProps.data.CR_subs_v }}%
                            <small v-if='slotProps.data.delta && slotProps.data.delta.CR_subs_v' class='delta'>
                                ({{ slotProps.data.delta.CR_subs_v }}%)</small>
                        </div>
                    </template>
                </Column>

                <Column field='active_subs' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Subscriptions attive"'></i> SubA
                    </template>
                    <template #body='slotProps'>
                        <div class='flex justify-content-center flex-wrap'>
                            <span class='flex align-items-center justify-content-center'>{{slotProps.data.active_subs}}</span>
                            <span  class='ml-2 flex align-items-start justify-content-center flex-column'>
                                <small><strong>FT2P</strong>: {{slotProps.data.subs_FT2p}}</small>
                                <small :class='{[tpl.win.winner]: slotProps.data.wins.CR_FT2p_FT, [tpl.win.looser]: !slotProps.data.wins.CR_FT2p_FT}'>
                                    <strong>CRF2P</strong>: {{slotProps.data.CR_FT2p_FT}}%
                                </small>
                            </span>
                        </div>
                    </template>
                </Column>

                <Column field='CR_active_sub' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Subscriptions attive/ Subscription totali"'></i> CR
                    </template>
                    <template #body='slotProps'>
                        <div>
                            {{ slotProps.data.CR_active_sub }}%
                            <small v-if='slotProps.data.delta && slotProps.data.delta.CR_active_sub'
                                   :class='smallClass(slotProps.data.delta.CR_active_sub)'>
                                ({{ slotProps.data.delta.CR_active_sub }}%)</small>
                        </div>
                    </template>
                </Column>

                <Column field='CR_active_sub_v' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Subscriptions attive / Downloads"'></i> CrT
                    </template>
                    <template #body='slotProps'>
                        <div
                            :class='{[tpl.win.winner]: slotProps.data.wins.CR_active_sub_v, [tpl.win.looser]: !slotProps.data.wins.CR_active_sub_v}'>
                            {{ slotProps.data.CR_active_sub_v }}%
                            <small v-if='slotProps.data.delta && slotProps.data.delta.CR_active_sub_v' class='delta'>
                                ({{ slotProps.data.delta.CR_active_sub_v }}%)</small>
                        </div>
                    </template>
                </Column>
                <Column field='ltv' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"LTV per le subscription attive"'></i> LTV
                    </template>
                    <template #body='slotProps'>
                        <div>
                            {{ slotProps.data.ltv }}€
                            <small v-if='slotProps.data.delta && slotProps.data.delta.ltv'
                                   :class='smallClass(slotProps.data.delta.ltv)'>
                                ({{ slotProps.data.delta.ltv }}%)</small>
                        </div>
                    </template>
                </Column>
                <Column field='ltv_v' :sortable='true' style='min-width:2rem' class='text-center'>
                    <template #header>
                        <i class='pi pi-info-circle mr-1 text-blue-600'
                           v-tooltip.top='"Media LTV per download"'></i> mLTVxV
                    </template>
                    <template #body='slotProps'>
                        <div
                            :class='{"bb": slotProps.data.wins.ltv_v, "font-bold": slotProps.data.wins.ltv_v, [tpl.win.winner]: slotProps.data.wins.ltv_v, [tpl.win.looser]: !slotProps.data.wins.ltv_v}'>
                            {{ slotProps.data.ltv_v }}€
                            <small v-if='slotProps.data.delta && slotProps.data.delta.ltv_v' class='delta'>
                                ({{ slotProps.data.delta.ltv_v }}%)</small>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>


<script>
export default {
    methods:{
        smallClass(data){
            if (!data) return "";
            if (typeof data.startsWith !== "function") return "";
            let cl = 'delta';
            if (data.startsWith('+')) cl += ' '+this.tpl.win.winner;
            if (data.startsWith('-')) cl += ' '+this.tpl.win.looser;
            return cl;
        }
    },
    props: {
        rows: {
            type: Array,
            required: true,
            default(){
                return [];
            }
        },
        tpl: {
            type: Object,
            default() {
                return {};
            },
            required: true
        }
    }
}
</script>

<style lang='scss'>

.bb{
    border: 1px solid #83f183;
    padding: 3px;
}
</style>
