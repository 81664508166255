<template>
    <div class='mt-4'>
        <h4>Signups</h4>
        <DataTable
            class='p-datatable-gridlines p-datatable-sm'
            :rowHover='false'
            dataKey='id'
            :value='rows'
        >
            <Column field='title' header='' :sortable='true' style='min-width:2rem' class='text-center'>
                <template #body='slotProps'>
                    <div
                        :class='{[slotProps.data.bg]: true}'
                        class='flex align-items-center justify-content-center border-round'
                        style='width:1.5rem;height:1.5rem'>
                        <i :class='{[slotProps.data.color]: true}'
                           class='pi text-md'>{{ slotProps.data.title }}</i>
                    </div>
                </template>
            </Column>
            <Column field='visitors' header='Visitors' :sortable='true' style='min-width:2rem'
                    class='text-center'>
            </Column>
            <Column field='users' header='Users' :sortable='true' style='min-width:2rem'
                    class='text-center'>
            </Column>
            <Column field='cr' :sortable='true' style='min-width:2rem' class='text-center'>
                <template #header><i class='pi pi-info-circle mr-1 text-blue-600'
                                     v-tooltip.top='"Signups/Totale visitors nella variante"'></i> CR
                </template>
                <template #body='slotProps'>
                    <div
                        :class='{[tpl.win.winner]: slotProps.data.winner, [tpl.win.looser]: !slotProps.data.winner}'>
                        {{ slotProps.data.cr }}%
                        <small v-if='slotProps.data.cr_delta' class='delta'>
                            ({{ slotProps.data.cr_delta }}%)</small>
                    </div>
                </template>
            </Column>
            <Column field='home_viewed' header='Home viewed' :sortable='true' style='min-width:2rem'
                    class='text-center'>
            </Column>
            <Column field='home_viewed_users' header='HV Users' :sortable='true' style='min-width:2rem'
                    class='text-center'>
            </Column>
            <Column field='cr_hv' :sortable='true' style='min-width:2rem' class='text-center'>
                <template #header><i class='pi pi-info-circle mr-1 text-blue-600'
                                     v-tooltip.top='"Totale utenti che vedono la home/Signups"'></i> CR
                </template>
                <template #body='slotProps'>
                    <div
                        :class='{[tpl.win.winner]: slotProps.data.cr_hv_win, [tpl.win.looser]: !slotProps.data.cr_hv_win}'>
                        {{ slotProps.data.cr_hv }}%
                        <small v-if='slotProps.data.cr_hv_delta' class='delta'>
                            ({{ slotProps.data.cr_hv_delta }}%)</small>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>

</template>

<script>
export default {
    props: {
        rows: {
            type: Array,
            required: true,
            default(){
                return [];
            }
        },
        tpl: {
            type: Object,
            default() {
                return {};
            },
            required: true
        }
    }
}
</script>
