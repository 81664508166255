<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Confronta KPIs sulle varianti</h4>
                </div>

                <div v-if='step > 0' class='surface-ground px-4 py-5 md:px-6 lg:px-8'>
                    <div class='grid flex justify-content-center'>
                        <div class='col-12 md:col-6 lg:col-3' >
                            <div class='surface-card shadow-2 p-3 border-round'>
                                <div class='flex justify-content-between mb-3'>
                                    <div>
                                        <span class='block text-500 font-medium mb-3'>Variante {{ v_1 }}</span>
                                        <div class='text-900 font-medium text-xl' v-html='abTest_1["name"]'>

                                        </div>
                                    </div>
                                    <div :class='{[tpl.first.bg]: true}'
                                         class='flex align-items-center justify-content-center border-round'
                                         style='width:2.5rem;height:2.5rem'>
                                        <i :class='{[tpl.first.color]: true}'
                                           class='pi text-xl'>{{ tpl.first.title }}</i>
                                    </div>
                                </div>
                                <span class='text-500'>Percentuale richiesta </span>
                                <span class='text-green-500 font-medium'>
                                    {{ v_1 === 'a' ? abTest_1['desired_perc_a'] : 100 - abTest_1['desired_perc_a'] }}%
                                </span>
                                <p v-if='result.a.visitors' class='mb-0'>
                                    <span class='text-500'>Partecipanti </span>
                                    <span class='text-green-500 font-medium'>{{ partecipants('a') }}</span>
                                </p>
                                <p class='mb-0' v-if='result.a.visitors && result.a.CR_checkout_completed_visitors'>
                                    <i class='pi pi-info-circle mr-1 text-blue-600'
                                       v-tooltip.top='"Checkout completed / partecipanti nella variante"'></i>
                                    <span class='text-500'>CR CoC / V </span>
                                    <span class='text-green-500 font-medium'>
                                        {{ result.a.CR_checkout_completed_visitors }}%
                                    </span>
                                </p>
                                <p class='mb-0' v-if='result.a.visitors && result.a.LTV'>
                                    <i class='pi pi-info-circle mr-1 text-blue-600'
                                       v-tooltip.top='"LTV totale / partecipanti nella variante"'></i>
                                    <span class='text-500'>LTV / V </span>
                                    <span class='text-green-500 font-medium'>
                                        {{ to2Digits(result.a.LTV / partecipants('a')) }}€
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div class='col-12 md:col-6 lg:col-3'>
                            <div class='surface-card shadow-2 p-3 border-round'>
                                <div class='flex justify-content-between mb-3'>
                                    <div>
                                        <span class='block text-500 font-medium mb-3'>Variante {{ v_2 }}</span>
                                        <div class='text-900 font-medium text-xl' v-html='abTest_2["name"]'>

                                        </div>
                                    </div>
                                    <div :class='{[tpl.last.bg]: true}'
                                         class='flex align-items-center justify-content-center border-round'
                                         style='width:2.5rem;height:2.5rem'>
                                        <i :class='{[tpl.last.color]: true}'
                                           class='pi text-cyan-500 text-xl'>{{ tpl.last.title }}</i>
                                    </div>
                                </div>
                                <span class='text-500'>Percentuale richiesta </span>
                                <span class='text-green-500 font-medium'>
                                    {{ v_2 === 'a' ? abTest_2['desired_perc_a'] : 100 - abTest_2['desired_perc_a'] }}%
                                </span>
                                <p class='mb-0' v-if='result.b.visitors'>
                                    <span class='text-500'>Partecipanti </span>
                                    <span
                                        class='text-green-500 font-medium'>{{ result.b.visitors + result.b.users }}</span>
                                </p>
                                <p class='mb-0' v-if='result.b.visitors && result.b.CR_checkout_completed_visitors'>
                                    <i class='pi pi-info-circle mr-1 text-blue-600'
                                       v-tooltip.top='"Checkout completed / partecipanti nella variante"'></i>
                                    <span class='text-500'>CR CoC / V </span>
                                    <span class='text-green-500 font-medium'>
                                        {{ result.b.CR_checkout_completed_visitors }}%
                                    </span>
                                </p>
                                <p class='mb-0' v-if='result.b.visitors && result.b.LTV'>
                                    <i class='pi pi-info-circle mr-1 text-blue-600'
                                       v-tooltip.top='"LTV totale / partecipanti nella variante"'></i>
                                    <span class='text-500'>LTV / V </span>
                                    <span class='text-green-500 font-medium'>
                                        {{ to2Digits(result.b.LTV / partecipants('b')) }}€
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class='grid'>
                        <div class='col-12'>
                            <span class="publish_state">Data da </span>
                            <Calendar
                                :showTime="true"
                                inputId="filter_date_from"
                                v-model="filter_data"
                                dateFormat="dd/mm/yy"
                            />
                            <Button @click='loadData' class='p-button-info ml-2' :style='{paddingTop: "9px", paddingBottom: "9px"}'>
                                <font-awesome-icon
                                    class='pointer'
                                    title='Filtra'
                                    :icon="['fas', 'filter']"
                                />
                            </Button>
                        </div>
                    </div>
                </div>

                <template v-if='step > 0' class='mt-4'>
                    <Signups :rows='visitorsTableContent' :tpl='tpl'/>
                </template>

                <template v-if='step > 1' class='mt-4'>
                    <Likes :tpl='tpl' :rows='feedBacksTableContent'/>
                </template>

                <template v-if='step > 2' class='mt-4'>
                    <Engagement :tpl='tpl' :rows='engagementTableContent'/>
                </template>

                <template class='mt-4' v-if='step > 3'>
                    <Checkouts :tpl='tpl' :rows='checkoutTableContent'/>
                </template>

                <div class='mt-4' v-if='step > 4'>
                    <h4>Subscriptions per piano</h4>
                    <div class='grid mt-3'>
                        <div class='col4 md:col-3'>
                            <span class='p-float-label'>
                                <InputNumber
                                    id='ltv_base_m'
                                    mode='currency'
                                    currency='EUR' locale='it-IT'
                                    :useGrouping='false'
                                    :maxFractionDigits='2'
                                    v-model="ltv_x_plan_type['1m']"
                                />
                                <label for='ltv_base_m'>LTV base mensile</label>
                            </span>
                        </div>
                        <div class='col4 md:col-3'>
                            <span class='p-float-label'>
                                <InputNumber
                                    id='ltv_base_y'
                                    mode='currency'
                                    currency='EUR' locale='it-IT'
                                    :useGrouping='false'
                                    :maxFractionDigits='2'
                                    v-model="ltv_x_plan_type['1y']"
                                />
                                <label for='ltv_base_y'>LTV base annuale</label>
                            </span>
                        </div>
                        <div class='col4 md:col-3'>
                            <span class='p-float-label'>
                                <InputNumber
                                    id='ltv_base_6m'
                                    mode='currency'
                                    currency='EUR' locale='it-IT'
                                    :useGrouping='false'
                                    :maxFractionDigits='2'
                                    v-model="ltv_x_plan_type['6m']"
                                />
                                <label for='ltv_base_6m'>LTV base semestrale</label>
                            </span>
                        </div>
                        <div class='col4 md:col-3'>
                            <span class='p-float-label'>
                                <InputNumber
                                    id='ltv_base_3m'
                                    mode='currency'
                                    currency='EUR' locale='it-IT'
                                    :useGrouping='false'
                                    :maxFractionDigits='2'
                                    v-model="ltv_x_plan_type['3m']"
                                />
                                <label for='ltv_base_6m'>LTV base trimestrale</label>
                            </span>
                        </div>
                    </div>
                    <div class='grid'>
                        <div class='col-6 text-center mb-1'>
                            <div :class='{[tpl.first.bg]: true}'
                                 class='flex align-items-center justify-content-center border-round'
                                 style='width:2.5rem;height:2.5rem'>
                                <i :class='{[tpl.first.color]: true}'
                                   class='pi text-xl'>{{ tpl.first.title }}</i>
                            </div>
                            <DataTable
                                class='p-datatable-gridlines p-datatable-sm mt-1'
                                :rowHover='false'
                                dataKey='id'
                                :value='subscriptionsXPlanA'
                                :rowClass='rowClass'
                            >
                                <template #header>
                                    <h5>Tutte le subscriptions</h5>
                                </template>
                                <Column field='id' header='ID' :sortable='true' style='min-width:1rem'
                                        class='text-center'>
                                </Column>
                                <Column field='name' header='Piano' :sortable='true' style='min-width:1rem'
                                        class='text-center'>
                                    <template #body='slotProps'>
                                        <div>
                                            {{ slotProps.data.name }} <span
                                            v-if='slotProps.data.trial'>({{ slotProps.data.trial }})</span>
                                        </div>
                                    </template>
                                </Column>
                                <Column field='num' header='Subs' :sortable='true' style='min-width:1rem'
                                        class='text-center'>
                                </Column>
                                <Column field='cr' :sortable='true' style='min-width:2rem' class='text-center'>
                                    <template #header>
                                        <i class='pi pi-info-circle mr-1 text-blue-600'
                                           v-tooltip.top='"Subscriptions / Signups"'></i> CR
                                    </template>
                                    <template #body='slotProps'>
                                        <div>
                                            {{ slotProps.data.cr }}%
                                        </div>
                                    </template>
                                </Column>
                                <Column field='id' :sortable='true' style='width:7rem'
                                        class='text-center'>
                                    <template #header>
                                        <i class='pi pi-info-circle mr-1 text-blue-600'
                                           v-tooltip.top='"LTV per subscription di questo piano"'></i> LTVu
                                    </template>
                                    <template #body='slotProps'>
                                        <InputNumber
                                            v-if='!slotProps.data.isTotalRow'
                                            :id='"ltv_A_"+slotProps.data.id'
                                            mode='currency'
                                            currency='EUR' locale='it-IT'
                                            :useGrouping='false'
                                            :maxFractionDigits='2'
                                            v-model="ltv_x_plan['p_'+slotProps.data.id]"
                                        />
                                    </template>
                                </Column>
                                <Column field='ltv' :sortable='true' style='min-width:2rem' class='text-center'>
                                    <template #header>
                                        <i class='pi pi-info-circle mr-1 text-blue-600'
                                           v-tooltip.top='"Valore totale per piano"'></i> LTV
                                    </template>
                                    <template #body='slotProps'>
                                        <div>
                                            {{ slotProps.data.ltv }}€
                                        </div>
                                    </template>
                                </Column>
                                <Column field='ltv_x_visitor' :sortable='true' style='min-width:2rem'
                                        class='text-center'>
                                    <template #header>
                                        <i class='pi pi-info-circle mr-1 text-blue-600'
                                           v-tooltip.top='"Valore totale / visitors "'></i> LTV medio
                                    </template>
                                    <template #body='slotProps'>
                                        <div>
                                            {{ slotProps.data.ltv_x_visitor }}€
                                        </div>
                                    </template>
                                </Column>
                            </DataTable>
                            <DataTable
                                class='p-datatable-gridlines p-datatable-sm mt-1'
                                :rowHover='false'
                                dataKey='id'
                                :value='activeSubscriptionsXPlanA'
                                :rowClass='rowClass'
                            >
                                <template #header>
                                    <h5>Subscriptions attivate</h5>
                                </template>
                                <Column field='id' header='ID' :sortable='true' style='min-width:1rem'
                                        class='text-center'>
                                </Column>
                                <Column field='name' header='Piano' :sortable='true' style='min-width:1rem'
                                        class='text-center'>
                                    <template #body='slotProps'>
                                        <div>
                                            {{ slotProps.data.name }} <span
                                            v-if='slotProps.data.trial'>({{ slotProps.data.trial }})</span>
                                        </div>
                                    </template>
                                </Column>
                                <Column field='num' header='Subs' :sortable='true' style='min-width:1rem'
                                        class='text-center'>
                                </Column>
                                <Column field='cr' :sortable='true' style='min-width:2rem' class='text-center'>
                                    <template #header>
                                        <i class='pi pi-info-circle mr-1 text-blue-600'
                                           v-tooltip.top='"Subscriptions / Signups"'></i> CR
                                    </template>
                                    <template #body='slotProps'>
                                        <div>
                                            {{ slotProps.data.cr }}%
                                        </div>
                                    </template>
                                </Column>
                                <Column field='id' :sortable='true' style='width:7rem'
                                        class='text-center'>
                                    <template #header>
                                        <i class='pi pi-info-circle mr-1 text-blue-600'
                                           v-tooltip.top='"LTV per subscription di questo piano"'></i> LTVu
                                    </template>
                                    <template #body='slotProps'>
                                        <InputNumber
                                            v-if='!slotProps.data.isTotalRow'
                                            :id='"ltv_A_"+slotProps.data.id'
                                            mode='currency'
                                            currency='EUR' locale='it-IT'
                                            :useGrouping='false'
                                            :maxFractionDigits='2'
                                            v-model="ltv_x_plan['p_'+slotProps.data.id]"
                                        />
                                    </template>
                                </Column>
                                <Column field='ltv' :sortable='true' style='min-width:2rem' class='text-center'>
                                    <template #header>
                                        <i class='pi pi-info-circle mr-1 text-blue-600'
                                           v-tooltip.top='"Valore totale per piano"'></i> LTV
                                    </template>
                                    <template #body='slotProps'>
                                        <div>
                                            {{ slotProps.data.ltv }}€
                                        </div>
                                    </template>
                                </Column>
                                <Column field='ltv_x_visitor' :sortable='true' style='min-width:2rem'
                                        class='text-center'>
                                    <template #header>
                                        <i class='pi pi-info-circle mr-1 text-blue-600'
                                           v-tooltip.top='"Valore totale / visitors "'></i> LTV medio
                                    </template>
                                    <template #body='slotProps'>
                                        <div>
                                            {{ slotProps.data.ltv_x_visitor }}€
                                        </div>
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                        <div class='col-6  text-center mb-1'>
                            <div :class='{[tpl.last.bg]: true}'
                                 class='flex align-items-center justify-content-center border-round'
                                 style='width:2.5rem;height:2.5rem'>
                                <i :class='{[tpl.last.color]: true}'
                                   class='pi text-xl'>{{ tpl.last.title }}</i>
                            </div>
                            <DataTable
                                class='p-datatable-gridlines p-datatable-sm mt-1'
                                :rowHover='false'
                                dataKey='id'
                                :value='subscriptionsXPlanB'
                                :rowClass='rowClass'
                            >
                                <template #header>
                                    <h5>Tutte le subscriptions</h5>
                                </template>
                                <Column field='id' header='ID' :sortable='true' style='min-width:1rem'
                                        class='text-center'>
                                </Column>
                                <Column field='name' header='Piano' :sortable='true' style='min-width:1rem'
                                        class='text-center'>
                                    <template #body='slotProps'>
                                        <div>
                                            {{ slotProps.data.name }} <span
                                            v-if='slotProps.data.trial'>({{ slotProps.data.trial }})</span>
                                        </div>
                                    </template>
                                </Column>
                                <Column field='num' header='Subs' :sortable='true' style='min-width:1rem'
                                        class='text-center'>
                                </Column>
                                <Column field='cr' :sortable='true' style='min-width:2rem' class='text-center'>
                                    <template #header>
                                        <i class='pi pi-info-circle mr-1 text-blue-600'
                                           v-tooltip.top='"Subscriptions / Signups"'></i> CR
                                    </template>
                                    <template #body='slotProps'>
                                        <div>
                                            {{ slotProps.data.cr }}%
                                        </div>
                                    </template>
                                </Column>
                                <Column field='id' :sortable='true' style='width:7rem'
                                        class='text-center'>
                                    <template #header>
                                        <i class='pi pi-info-circle mr-1 text-blue-600'
                                           v-tooltip.top='"LTV per subscription di questo piano"'></i> LTVu
                                    </template>
                                    <template #body='slotProps'>
                                        <InputNumber
                                            v-if='!slotProps.data.isTotalRow'
                                            :id='"ltv_A_"+slotProps.data.id'
                                            mode='currency'
                                            currency='EUR' locale='it-IT'
                                            :useGrouping='false'
                                            :maxFractionDigits='2'
                                            v-model="ltv_x_plan['p_'+slotProps.data.id]"
                                        />
                                    </template>
                                </Column>
                                <Column field='ltv' :sortable='true' style='min-width:2rem' class='text-center'>
                                    <template #header>
                                        <i class='pi pi-info-circle mr-1 text-blue-600'
                                           v-tooltip.top='"Valore totale per piano"'></i> LTV
                                    </template>
                                    <template #body='slotProps'>
                                        <div>
                                            {{ slotProps.data.ltv }}€
                                        </div>
                                    </template>
                                </Column>
                                <Column field='ltv_x_visitor' :sortable='true' style='min-width:2rem'
                                        class='text-center'>
                                    <template #header>
                                        <i class='pi pi-info-circle mr-1 text-blue-600'
                                           v-tooltip.top='"Valore totale / visitors "'></i> LTV medio
                                    </template>
                                    <template #body='slotProps'>
                                        <div>
                                            {{ slotProps.data.ltv_x_visitor }}€
                                        </div>
                                    </template>
                                </Column>
                            </DataTable>
                            <DataTable
                                class='p-datatable-gridlines p-datatable-sm mt-1'
                                :rowHover='false'
                                dataKey='id'
                                :value='activeSubscriptionsXPlanB'
                                :rowClass='rowClass'
                            >
                                <template #header>
                                    <h5>Subscriptions attivate</h5>
                                </template>
                                <Column field='id' header='ID' :sortable='true' style='min-width:1rem'
                                        class='text-center'>
                                </Column>
                                <Column field='name' header='Piano' :sortable='true' style='min-width:1rem'
                                        class='text-center'>
                                    <template #body='slotProps'>
                                        <div>
                                            {{ slotProps.data.name }} <span
                                            v-if='slotProps.data.trial'>({{ slotProps.data.trial }})</span>
                                        </div>
                                    </template>
                                </Column>
                                <Column field='num' header='Subs' :sortable='true' style='min-width:1rem'
                                        class='text-center'>
                                </Column>
                                <Column field='cr' :sortable='true' style='min-width:2rem' class='text-center'>
                                    <template #header>
                                        <i class='pi pi-info-circle mr-1 text-blue-600'
                                           v-tooltip.top='"Subscriptions / Signups"'></i> CR
                                    </template>
                                    <template #body='slotProps'>
                                        <div>
                                            {{ slotProps.data.cr }}%
                                        </div>
                                    </template>
                                </Column>
                                <Column field='id' :sortable='true' style='width:7rem'
                                        class='text-center'>
                                    <template #header>
                                        <i class='pi pi-info-circle mr-1 text-blue-600'
                                           v-tooltip.top='"LTV per subscription di questo piano"'></i> LTVu
                                    </template>
                                    <template #body='slotProps'>
                                        <InputNumber
                                            v-if='!slotProps.data.isTotalRow'
                                            :id='"ltv_A_"+slotProps.data.id'
                                            mode='currency'
                                            currency='EUR' locale='it-IT'
                                            :useGrouping='false'
                                            :maxFractionDigits='2'
                                            v-model="ltv_x_plan['p_'+slotProps.data.id]"
                                        />
                                    </template>
                                </Column>
                                <Column field='ltv' :sortable='true' style='min-width:2rem' class='text-center'>
                                    <template #header>
                                        <i class='pi pi-info-circle mr-1 text-blue-600'
                                           v-tooltip.top='"Valore totale per piano"'></i> LTV
                                    </template>
                                    <template #body='slotProps'>
                                        <div>
                                            {{ slotProps.data.ltv }}€
                                        </div>
                                    </template>
                                </Column>
                                <Column field='ltv_x_visitor' :sortable='true' style='min-width:2rem'
                                        class='text-center'>
                                    <template #header>
                                        <i class='pi pi-info-circle mr-1 text-blue-600'
                                           v-tooltip.top='"Valore totale / visitors "'></i> LTV medio
                                    </template>
                                    <template #body='slotProps'>
                                        <div>
                                            {{ slotProps.data.ltv_x_visitor }}€
                                        </div>
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                    <Summary :tpl='tpl' :rows='winnerData' />
                    <Funnel :tpl='tpl' :rows='funnelData' />
                </div>

                <div class='grid'>
                    <div class='col-12 text-center'>
                        <ProgressSpinner v-if='loading' />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Analytics/Abtests');
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import ProgressSpinner from 'primevue/progressspinner';
import tools from '../../../libs/tools';

import Signups from './parts/Signups';
import Likes from './parts/Likes';
import Engagement from './parts/Engagement';
import Checkouts from './parts/Checkouts';
import Summary from './parts/Summary';
import Funnel from './parts/Funnel';

export default {
    mixins: [Notifications, Navigations],
    data() {
        return {
            refreshSubscriptions: false,
            step: 0,
            ltv_x_plan_type: {
                '1m': 45,
                '1y': 125,
                '6m': 60,
                '3m': 70,
            },
            A: {},
            B: {},
            ltv_x_plan: {},
            filter_data: null,
            abTest_1: null,
            abTest_2: null,
            result: {
                a: {},
                b: {},
            },
            tpl: {
                first: {
                    title: 'A',
                    bg: 'bg-orange-100',
                    color: 'text-orange-500',
                },
                last: {
                    title: 'B',
                    bg: 'bg-cyan-100',
                    color: 'text-cyan-500',
                },
                win: {
                    winner: 'bg-green-100',
                    winnerText: 'text-green-700',
                    looser: 'bg-pink-50',
                    looserText: 'text-pink-700',
                },
            },
        };
    },
    computed: {
        ...mapGetters(['loading', 'resources']),
        visitorsTableContent() {
            const partA = this.partecipants('a');
            const partB = this.partecipants('b');

            const crA = this.toPerc(this.result.a.users / partA);
            const crB = this.toPerc(this.result.b.users / partB);

            const crHA = this.toPerc(this.result.a['home_viewed_users']/this.result.a.users) ;
            const crHB = this.toPerc(this.result.b['home_viewed_users']/this.result.b.users) ;

            return [{
                ...this.tpl.first,
                id: 'v-a',
                visitors: partA,
                users: this.result.a.users,
                cr: crA,
                winner: crA > crB,
                home_viewed: this.result.a['home_viewed'],
                home_viewed_users: this.result.a['home_viewed_users'],
                cr_hv: crHA,
                cr_hv_win: crHA > crHB
            }, {
                ...this.tpl.last,
                id: 'v-b',
                visitors: partB,
                users: this.result.b.users,
                cr: crB,
                cr_delta: this.findDelta(crA, crB),
                winner: crA < crB,
                home_viewed: this.result.b['home_viewed'],
                home_viewed_users: this.result.b['home_viewed_users'],
                cr_hv: crHB,
                cr_hv_win: crHA < crHB,
                cr_hv_delta: this.findDelta(crHA, crHB),
            }];
        },
        engagementTableContent() {
            const spuA = this.result.a.books_started / this.result.a.books_started_users;
            const spuB = this.result.b.books_started / this.result.b.books_started_users;

            const crsA = this.result.a.books_started_users / this.result.a.users;
            const crsB = this.result.b.books_started_users / this.result.b.users;

            const cpuA = this.result.a.books_completed / this.result.a.books_completed_users;
            const cpuB = this.result.b.books_completed / this.result.b.books_completed_users;

            const crcA = this.result.a.books_completed_users / this.result.a.users;
            const crcB = this.result.b.books_completed_users / this.result.b.users;

            const bpuA = this.result.a.favourite_books / this.result.a.favourite_books_users;
            const bpuB = this.result.b.favourite_books / this.result.b.favourite_books_users;

            const crbA = this.result.a.favourite_books_users / this.result.a.users;
            const crbB = this.result.b.favourite_books_users / this.result.b.users;


            return [
                {
                    ...this.tpl.first,
                    id: 'e-a',
                    started: this.result.a.books_started,
                    started_users: this.result.a.books_started_users,
                    completed: this.result.a.books_completed,
                    completed_users: this.result.a.books_completed_users,
                    favourites: this.result.a.favourite_books,
                    favourites_users: this.result.a.favourite_books_users,

                    cpu: this.to2Digits(cpuA),
                    spu: this.to2Digits(spuA),
                    bpu: this.to2Digits(bpuA),

                    crs: this.toPerc(crsA),
                    crc: this.toPerc(crcA),
                    crb: this.toPerc(crbA),

                    crbWin: crbA > crbB,
                    crsWin: crsA > crsB,
                    bpuWin: bpuA > bpuB,
                    crcWin: crcA > crcB,
                    bsWin: spuA > spuB,
                    cpWin: cpuA > cpuB,
                    winner: crcA > crcB,
                },
                {
                    ...this.tpl.last,
                    id: 'e-b',
                    started: this.result.b.books_started,
                    started_users: this.result.b.books_started_users,
                    completed: this.result.b.books_completed,
                    completed_users: this.result.b.books_completed_users,
                    favourites: this.result.b.favourite_books,
                    favourites_users: this.result.b.favourite_books_users,

                    cpu: this.to2Digits(cpuB),
                    spu: this.to2Digits(spuB),
                    bpu: this.to2Digits(bpuB),

                    crs: this.toPerc(crsB),
                    crc: this.toPerc(crcB),
                    crb: this.toPerc(crbB),

                    cpu_delta: this.findDelta(cpuA, cpuB),
                    spu_delta: this.findDelta(spuA, spuB),
                    crs_delta: this.findDelta(crsA, crsB),
                    bpu_delta: this.findDelta(bpuA, bpuB),
                    crb_delta: this.findDelta(crbA, crbB),
                    crc_delta: this.findDelta(crcA, crcB),
                    crbWin: crbA < crbB,
                    crsWin: crsA < crsB,
                    crcWin: crcA < crcB,
                    bpuWin: bpuA < bpuB,
                    bsWin: spuA < spuB,
                    cpWin: cpuA < cpuB,
                    winner: crcA < crcB,
                },
            ];
        },
        feedBacksTableContent() {
            let Awins = 0;

            const bpuA = this.result.a.book_feedbacks / this.result.a.book_feedbacks_users;
            const bpuB = this.result.b.book_feedbacks / this.result.b.book_feedbacks_users;
            if (bpuA >= bpuB) Awins++;

            const crbA = this.result.a.book_feedbacks_users / this.result.a.users;
            const crbB = this.result.b.book_feedbacks_users / this.result.b.users;
            if (crbA >= crbB) Awins++;

            const cpuA = this.result.a.category_feedbacks / this.result.a.category_feedbacks_users;
            const cpuB = this.result.b.category_feedbacks / this.result.b.category_feedbacks_users;
            if (cpuA >= cpuB) Awins++;

            const crcA = this.result.a.category_feedbacks_users / this.result.a.users;
            const crcB = this.result.b.category_feedbacks_users / this.result.b.users;
            if (crcA >= crcB) Awins++;

            const spuA = this.result.a.skill_feedbacks / this.result.a.skill_feedbacks_users;
            const spuB = this.result.b.skill_feedbacks / this.result.b.skill_feedbacks_users;
            if (spuA >= spuB) Awins++;

            const crsA = this.result.a.skill_feedbacks_users / this.result.a.users;
            const crsB = this.result.b.skill_feedbacks_users / this.result.b.users;
            if (crsA >= crsB) Awins++;
            return [{
                ...this.tpl.first,
                id: 'f-a',
                winner: Awins >= 5,
                books: this.result.a.book_feedbacks,
                books_users: this.result.a.book_feedbacks_users,
                categories: this.result.a.category_feedbacks,
                categories_users: this.result.a.category_feedbacks_users,
                skills: this.result.a.skill_feedbacks,
                skills_users: this.result.a.skill_feedbacks_users,
                bpu: this.to2Digits(bpuA),
                cpu: this.to2Digits(cpuA),
                spu: this.to2Digits(spuA),
                crbWin: crbA > crbB,
                crcWin: crcA > crcB,
                crb: this.toPerc(crbA),
                crc: this.toPerc(crcA),
                crs: this.toPerc(crsA),
                crsWin: crsA > crsB,
                bpuWin: bpuA > bpuB,
                cpuWin: cpuA > cpuB,
                spuWin: spuA > spuB,
            }, {
                ...this.tpl.last,
                id: 'f-b',
                winner: Awins < 5,
                books: this.result.b.book_feedbacks,
                books_users: this.result.b.book_feedbacks_users,
                categories: this.result.b.category_feedbacks,
                categories_users: this.result.b.category_feedbacks_users,
                skills: this.result.b.skill_feedbacks,
                skills_users: this.result.b.skill_feedbacks_users,
                crbWin: crbA < crbB,
                crcWin: crcA < crcB,
                bpu_delta: this.findDelta(bpuA, bpuB),
                cpu_delta: this.findDelta(cpuA, cpuB),
                spu_delta: this.findDelta(spuA, spuB),
                bpu: this.to2Digits(bpuB),
                cpu: this.to2Digits(cpuB),
                spu: this.to2Digits(spuB),
                crb: this.toPerc(crbB),
                crc: this.toPerc(crcB),
                crs: this.toPerc(crsB),
                crb_delta: this.findDelta(crbA, crbB),
                crc_delta: this.findDelta(crcA, crcB),
                crs_delta: this.findDelta(crsA, crsB),
                crsWin: crsA < crsB,
                bpuWin: bpuA < bpuB,
                cpuWin: cpuA < cpuB,
                spuWin: spuA < spuB,
            }];
        },
        checkoutTableContent() {
            const crvA = this.result.a.checkout_viewed_users / this.result.a.users;
            const crvB = this.result.b.checkout_viewed_users / this.result.b.users;

            const crsA = this.result.a.checkout_started_users / this.result.a.users;
            const crsB = this.result.b.checkout_started_users / this.result.b.users;

            const crcA = this.result.a.checkout_completed_users / this.result.a.users;
            const crcB = this.result.b.checkout_completed_users / this.result.b.users;

            return [
                {
                    ...this.tpl.first,
                    id: 'ck-a',
                    viewed: this.result.a.checkout_viewed,
                    started: this.result.a.checkout_started,
                    completed: this.result.a.checkout_completed,
                    viewed_users: this.result.a.checkout_viewed_users,
                    started_users: this.result.a.checkout_started_users,
                    completed_users: this.result.a.checkout_completed_users,
                    crvWin: crvA > crvB,
                    crv: this.toPerc(crvA),
                    crs: this.toPerc(crsA),
                    crc: this.toPerc(crcA),
                    crsWin: crsA > crsB,
                    crcWin: crcA > crcB,
                    winner: crcA > crcB,
                },
                {
                    ...this.tpl.last,
                    id: 'ck-b',
                    viewed: this.result.b.checkout_viewed,
                    started: this.result.b.checkout_started,
                    completed: this.result.b.checkout_completed,
                    viewed_users: this.result.b.checkout_viewed_users,
                    started_users: this.result.b.checkout_started_users,
                    completed_users: this.result.b.checkout_completed_users,
                    crv_delta: this.findDelta(crvA, crvB),
                    crvWin: crvA < crvB,
                    crv: this.toPerc(crvB),
                    crs: this.toPerc(crsB),
                    crc: this.toPerc(crcB),
                    crs_delta: this.findDelta(crsA, crsB),
                    crsWin: crsA < crsB,
                    crc_delta: this.findDelta(crcA, crcB),
                    crcWin: crcA < crcB,
                    winner: crcA < crcB,
                },
            ];
        },
        activeSubscriptionsXPlanA() {
            const ret = [];
            const arr = this.result.a.active_subscriptions_per_plan;
            let Tsub = 0;
            let Tltv = 0;
            arr.forEach(row => {
                if (!row['plan']) row['plan'] = {};
                row.plan.id = row.plan.id || '-';
                row.plan.name = row.plan.name || 'Unknown';
                row.plan.period = row.plan.period || 1;
                row.plan.period_unit = row.plan.period_unit || 'y';
                row.plan.trial_period = row.plan.trial_period || 0;
                row.plan.trial_period_unit = row.plan.trial_period_unit || 'd';
                const ltv = Math.round(row.num * this.planValue(row.plan));
                Tsub += row.num;
                Tltv += row.num * this.planValue(row.plan);
                ret.push({
                    id: row.plan.id,
                    num: row.num,
                    name: row.plan.name,
                    period: row.plan.period + row.plan.period_unit,
                    value: this.planValue(row.plan),
                    trial: row.plan.trial_period ? row.plan.trial_period + row.plan.trial_period_unit : '',
                    cr: this.toPerc(row.num / this.result.a.users),
                    ltv: ltv,
                    ltv_x_visitor: this.to2Digits(ltv / this.partecipants('a')),
                });
            });
            //Totale
            ret.push({
                id: '',
                foo: this.refreshSubscriptions,
                isTotalRow: true,
                num: Tsub,
                name: 'TOTALE',
                period: '',
                value: '',
                trial: '',
                cr: this.toPerc(Tsub / this.result.a.users),
                ltv: Tltv,
                ltv_x_visitor: this.to2Digits(Tltv / this.partecipants('a')),
            });
            return ret;
        },
        activeSubscriptionsXPlanB() {
            const ret = [];
            const arr = this.result.b.active_subscriptions_per_plan;
            let Tsub = 0;
            let Tltv = 0;
            arr.forEach(row => {
                if (!row['plan']) row['plan'] = {};
                row.plan.id = row.plan.id || '-';
                row.plan.name = row.plan.name || 'Unknown';
                row.plan.period = row.plan.period || 1;
                row.plan.period_unit = row.plan.period_unit || 'y';
                row.plan.trial_period = row.plan.trial_period || 0;
                row.plan.trial_period_unit = row.plan.trial_period_unit || 'd';
                const ltv = Math.round(row.num * this.planValue(row.plan));
                Tsub += row.num;
                Tltv += row.num * this.planValue(row.plan);
                ret.push({
                    id: row.plan.id,
                    num: row.num,
                    name: row.plan.name,
                    period: row.plan.period + row.plan.period_unit,
                    value: this.planValue(row.plan),
                    trial: row.plan.trial_period ? row.plan.trial_period + row.plan.trial_period_unit : '',
                    cr: this.toPerc(row.num / this.result.b.users),
                    ltv: ltv,
                    ltv_x_visitor: this.to2Digits(ltv / this.partecipants('b')),
                });
            });
            //Totale
            ret.push({
                id: '',
                foo: this.refreshSubscriptions,
                isTotalRow: true,
                num: Tsub,
                name: 'TOTALE',
                period: '',
                value: '',
                trial: '',
                cr: this.toPerc(Tsub / this.result.b.users),
                ltv: Tltv,
                ltv_x_visitor: this.to2Digits(Tltv / this.partecipants('b')),
            });
            return ret;
        },
        subscriptionsXPlanA() {
            const ret = [];
            const arr = this.result.a.subscriptions_per_plan;
            let Tsub = 0;
            let Tltv = 0;
            arr.forEach(row => {
                if (!row['plan']) row['plan'] = {};
                row.plan.id = row.plan.id || '-';
                row.plan.name = row.plan.name || 'Unknown';
                row.plan.period = row.plan.period || 1;
                row.plan.period_unit = row.plan.period_unit || 'y';
                row.plan.trial_period = row.plan.trial_period || 0;
                row.plan.trial_period_unit = row.plan.trial_period_unit || 'd';
                const ltv = Math.round(row.num * this.planValue(row.plan));
                Tsub += row.num;
                Tltv += row.num * this.planValue(row.plan);
                ret.push({
                    id: row.plan.id,
                    num: row.num,
                    name: row.plan.name,
                    period: row.plan.period + row.plan.period_unit,
                    value: this.planValue(row.plan),
                    trial: row.plan.trial_period ? row.plan.trial_period + row.plan.trial_period_unit : '',
                    cr: this.toPerc(row.num / this.result.a.users),
                    ltv: ltv,
                    ltv_x_visitor: this.to2Digits(ltv / this.partecipants('a')),
                });
            });
            //Totale
            ret.push({
                id: '',
                foo: this.refreshSubscriptions,
                isTotalRow: true,
                num: Tsub,
                name: 'TOTALE',
                period: '',
                value: '',
                trial: '',
                cr: this.toPerc(Tsub / this.result.a.users),
                ltv: Tltv,
                ltv_x_visitor: this.to2Digits(Tltv / this.partecipants('a')),
            });
            return ret;
        },
        subscriptionsXPlanB() {
            const ret = [];
            const arr = this.result.b.subscriptions_per_plan;
            let Tsub = 0;
            let Tltv = 0;
            arr.forEach(row => {
                if (!row['plan']) row['plan'] = {};
                row.plan.id = row.plan.id || '-';
                row.plan.name = row.plan.name || 'Unknown';
                row.plan.period = row.plan.period || 1;
                row.plan.period_unit = row.plan.period_unit || 'y';
                row.plan.trial_period = row.plan.trial_period || 0;
                row.plan.trial_period_unit = row.plan.trial_period_unit || 'd';
                const ltv = Math.round(row.num * this.planValue(row.plan));
                Tsub += row.num;
                Tltv += row.num * this.planValue(row.plan);
                ret.push({
                    id: row.plan.id,
                    num: row.num,
                    name: row.plan.name,
                    period: row.plan.period + row.plan.period_unit,
                    value: this.planValue(row.plan),
                    trial: row.plan.trial_period ? row.plan.trial_period + row.plan.trial_period_unit : '',
                    cr: this.toPerc(row.num / this.result.b.users),
                    ltv: ltv,
                    ltv_x_visitor: this.to2Digits(ltv / this.partecipants('b')),
                });
            });
            //Totale
            ret.push({
                id: '',
                foo: this.refreshSubscriptions,
                isTotalRow: true,
                num: Tsub,
                name: 'TOTALE',
                period: '',
                value: '',
                trial: '',
                cr: this.toPerc(Tsub / this.result.b.users),
                ltv: Tltv,
                ltv_x_visitor: this.to2Digits(Tltv / this.partecipants('b')),
            });
            return ret;
        },
        winnerData() {
            const A = { ...this.tpl.first, id: 'w-a' };
            const B = { ...this.tpl.last, id: 'w-b' };
            let g = 'a';
            A.partecipants = this.partecipants(g);
            A.subs = this.result[g].TOT_subs;
            A.active_subs = this.result[g].TOT_subs_activated;
            A.free_trials = this.result[g].free_trials;
            A.ft2paid = this.result[g].free_trials_to_paid;
            A.ltv = this.result[g].LTV;
            A.subs_CR_s = this.toPerc(A.subs / this.result[g].users);
            A.subs_CR_v = this.toPerc(A.subs / A.partecipants);
            A.active_sub_CR_s = this.toPerc(A.active_subs / this.result[g].users);
            A.active_sub_CR_v = this.toPerc(A.active_subs / A.partecipants);
            A.ft2paid_CR = this.toPerc(A.ft2paid / A.free_trials);
            A.ltv_x_user = this.to2Digits(A.ltv / this.result[g].users);
            A.ltv_x_part = this.to2Digits(A.ltv / A.partecipants);

            g = 'b';
            B.partecipants = this.partecipants(g);
            B.subs = this.result[g].TOT_subs;
            B.active_subs = this.result[g].TOT_subs_activated;
            B.free_trials = this.result[g].free_trials;
            B.ft2paid = this.result[g].free_trials_to_paid;
            B.ltv = this.result[g].LTV;
            B.subs_CR_s = this.toPerc(B.subs / this.result[g].users);
            B.subs_CR_v = this.toPerc(B.subs / B.partecipants);
            B.active_sub_CR_s = this.toPerc(B.active_subs / this.result[g].users);
            B.active_sub_CR_v = this.toPerc(B.active_subs / B.partecipants);
            B.ft2paid_CR = this.toPerc(B.ft2paid / B.free_trials);
            B.ltv_x_user = this.to2Digits(B.ltv / this.result[g].users);
            B.ltv_x_part = this.to2Digits(B.ltv / B.partecipants);
            B.foo = this.refreshSubscriptions;

            A.wins = {};
            B.wins = {};
            B.delta = {};
            const compare = ['subs', 'active_subs', 'free_trials', 'ft2paid', 'ltv', 'subs_CR_s', 'subs_CR_v',
                'active_sub_CR_s', 'active_sub_CR_v', 'ft2paid_CR', 'ltv_x_user', 'ltv_x_part'];
            compare.forEach(k => {
                A.wins[k] = A[k] > B[k];
                B.wins[k] = A[k] < B[k];
                B.delta[k] = this.findDelta(A[k], B[k])
            });

            return [A, B];
        },
        funnelData(){
            const A = { ...this.tpl.first, id: 'fun-a' };
            const B = { ...this.tpl.last, id: 'fun-b' };

            let g = 'a';
            A.partecipants = this.partecipants(g);
            A.signups = this.result[g].users;
            A.CR_signups = this.toPerc(A.signups/A.partecipants);
            A.subs = this.result[g].TOT_subs;
            A.CR_subs = this.toPerc(A.subs/A.signups);
            A.CR_subs_v = this.toPerc(A.subs/A.partecipants);
            A.subs_FT = this.subsCountBoolFreeTrials(this.result[g].subscriptions_per_plan, true);
            A.subs_NFT = this.subsCountBoolFreeTrials(this.result[g].subscriptions_per_plan, false);
            A.CR_FT = this.toPerc(A.subs_FT/A.signups);
            A.CR_NFT = this.toPerc(A.subs_NFT/A.signups);
            A.subs_FT2p = this.subsCountBoolFreeTrials(this.result[g].active_subscriptions_per_plan, true);
            A.CR_FT2p_FT = this.toPerc(A.subs_FT2p / A.subs_FT);
            A.CR_FT2p_FT_v = this.toPerc(A.subs_FT2p / A.partecipants);
            A.active_subs = this.result[g].TOT_subs_activated;
            A.CR_active_sub = this.toPerc(A.active_subs/A.subs);
            A.CR_active_sub_v = this.toPerc(A.active_subs/A.partecipants);
            A.ltv = this.result[g].LTV;
            A.ltv_v = this.to2Digits(A.ltv/A.partecipants);

            A.checkout_started = this.result[g].checkout_started_users;
            A.CR_co_started = this.toPerc(A.checkout_started/A.signups);
            A.CR_co_started_v = this.toPerc(A.checkout_started/A.partecipants);
            A.CR_subs_co = this.toPerc(A.subs/A.checkout_started);

            g = 'b';
            B.partecipants = this.partecipants(g);
            B.signups = this.result[g].users;
            B.CR_signups = this.toPerc(B.signups/B.partecipants);
            B.subs = this.result[g].TOT_subs;
            B.CR_subs = this.toPerc(B.subs/B.signups);
            B.CR_subs_v = this.toPerc(B.subs/B.partecipants);
            B.subs_FT = this.subsCountBoolFreeTrials(this.result[g].subscriptions_per_plan, true);
            B.subs_NFT = this.subsCountBoolFreeTrials(this.result[g].subscriptions_per_plan, false);
            B.CR_FT = this.toPerc(B.subs_FT/B.signups);
            B.CR_NFT = this.toPerc(B.subs_NFT/B.signups);
            B.subs_FT2p = this.subsCountBoolFreeTrials(this.result[g].active_subscriptions_per_plan, true);
            B.CR_FT2p_FT = this.toPerc(B.subs_FT2p / B.subs_FT);
            B.CR_FT2p_FT_v = this.toPerc(B.subs_FT2p / B.partecipants);
            B.active_subs = this.result[g].TOT_subs_activated;
            B.CR_active_sub = this.toPerc(B.active_subs/B.subs);
            B.CR_active_sub_v = this.toPerc(B.active_subs/B.partecipants);
            B.ltv = this.result[g].LTV;
            B.ltv_v = this.to2Digits(B.ltv/B.partecipants);
            B.checkout_started = this.result[g].checkout_started_users;
            B.CR_co_started = this.toPerc(B.checkout_started/B.signups);
            B.CR_co_started_v = this.toPerc(B.checkout_started/B.partecipants);
            B.CR_subs_co = this.toPerc(B.subs/B.checkout_started);

            A.wins = {};
            B.wins = {};
            B.delta = {};
            const compare = ['CR_signups', 'CR_subs_v', 'CR_FT2p_FT_v', 'CR_active_sub_v', 'ltv_v', 'CR_co_started_v',
                'CR_co_started', 'CR_subs_co', 'CR_FT2p_FT', 'CR_active_sub', 'ltv'];
            compare.forEach(k => {
                A.wins[k] = A[k] > B[k];
                B.wins[k] = A[k] < B[k];
                B.delta[k] = this.findDelta(A[k], B[k])
            });
            return [A, B];
        }
    },
    watch: {
        'ltv_x_plan_type.1m'() {
            this.resetLTVxPlan();
        },
        'ltv_x_plan_type.6m'() {
            this.resetLTVxPlan();
        },
        'ltv_x_plan_type.3m'() {
            this.resetLTVxPlan();
        },
        'ltv_x_plan_type.1y'() {
            this.resetLTVxPlan();
        }

    },
    props: {
        ab_1: {
            type: Number,
            required: true,
        },
        ab_2: {
            type: Number,
            required: true,
        },
        v_1: {
            type: String,
            required: true,
            default() {
                return 'a';
            },
        },
        v_2: {
            type: String,
            required: true,
            default() {
                return 'b';
            },
        },

    },
    components: { ProgressSpinner, Signups, Likes, Engagement, Checkouts, Summary, Funnel },
    mounted() {
        this.loadData(false);
    },
    methods: {
        ...mapActions(['compare']),
        rowClass(data) {
            if (data.isTotalRow) return 'bg-orange-50 font-bold';
            return '';
        },
        loadData(proceed = true) {
            this.resetSteps();
            this.fetchStepOne(proceed);
        },
        resetSteps() {
            this.step = 0;
            this.A = {};
            this.B = {};
            const fp = {
                users: 0,
                visitors: 0,
                subscriptions_per_plan: null,
                active_subscriptions_per_plan: null,
                skill_feedbacks: 0,
                skill_feedbacks_users: 0,
                home_viewed: 0,
                home_viewed_users: 0,
                books_started: 0,
                books_started_users: 0,
                books_completed: 0,
                books_completed_users: 0,
                category_feedbacks: 0,
                category_feedbacks_users: 0,
                checkout_viewed_users: 0,
                checkout_started_users: 0,
                checkout_completed_users: 0,

                checkout_viewed: 0,
                checkout_started: 0,
                checkout_completed: 0,

                favourite_books: 0,
                favourite_books_users: 0,
                book_feedbacks: 0,
                book_feedbacks_users: 0,
                free_trials: 0,
                free_trials_to_paid: 0,
                CR_checkout_completed_visitors: 0,
                LTV: 0,
                TOT_subs: 0,
                TOT_subs_activated: 0,
            };
            this.result['a'] = { ...fp };
            this.result['b'] = { ...fp };
        },
        resetLTVxPlan() {
            this.ltv_x_plan = {};
            this.refreshSubscriptions = !this.refreshSubscriptions;
            this.findTotalLTV();
        },
        planValue(plan) {
            if (this.ltv_x_plan['p_' + plan.id]) return this.ltv_x_plan['p_' + plan.id];
            const dim = plan['period'] + plan['period_unit'];
            if (this.ltv_x_plan_type[dim]) {
                this.ltv_x_plan['p_' + plan.id] = this.ltv_x_plan_type[dim];
                return this.ltv_x_plan_type[dim];
            }
            return 0;
        },
        fillFake(n) {
            return n;
            /*
            if (process.env.NODE_ENV !== 'development') {
                return n;
            }
            return Math.floor(Math.random() * 1000);
            */
        },
        assignValues(res, key) {
            res['a'][key] = res['a'][key] || 0;
            res['b'][key] = res['b'][key] || 0;

            this.result.a[key] = this.fillFake(res['a'][key]);
            this.result.b[key] = this.fillFake(res['b'][key]);
        },
        assignObject(res, key) {
            res['a'][key] = res['a'][key] || [];
            res['b'][key] = res['b'][key] || [];

            this.result.a[key] = res['a'][key];
            this.result.b[key] = res['b'][key];

            /*
            if (process.env.NODE_ENV !== 'development') {
                this.result.a[key] = res['a'][key];
                this.result.b[key] = res['b'][key];

                return;
            }
            this.result.a[key] = [];
            this.result.b[key] = [];

            const plans = [
                {
                    id: 1,
                    name: 'monthly',
                    period: 1,
                    period_unit: 'm',
                    trial_period: 7,
                    trial_period_unit: 'd',
                },
                {
                    id: 2,
                    name: 'yearly',
                    period: 1,
                    period_unit: 'y',
                    trial_period: 7,
                    trial_period_unit: 'd',
                },
                {
                    id: 3,
                    name: 'six-month',
                    period: 6,
                    period_unit: 'm',
                    trial_period: 0,
                    trial_period_unit: 'd',
                },
            ];

            plans.forEach(plan => {
                this.result.a[key].push({
                    plan: plan,
                    num: this.fillFake(0),
                });
                this.result.b[key].push({
                    plan: plan,
                    num: this.fillFake(0),
                });
            });
            */
        },
        commonPayload(w) {
            const payload = {
                ab1: this.ab_1,
                ab2: this.ab_2,
                v1: this.v_1,
                v2: this.v_2,
                what: w,
            };
            if (this.filter_data){
                payload['from1'] = this.filter_data;
                payload['from2'] = this.filter_data;
            }
            return payload;
        },
        fetchStepOne(proceed = true) {
            const payload = {
                ...this.commonPayload({
                    [this.resources.VISITORS]: true,
                    [this.resources.USERS]: true,
                    [this.resources.HOME_VIEWED]: true
                }),
            };
            this.compare(payload)
                .then(res => {
                    const a = res['a'];
                    const b = res['b'];
                    this.abTest_1 = a['ab_test'];
                    this.abTest_2 = b['ab_test'];
                    this.filter_data = tools.time.api2Date(a['filter_date']);

                    this.assignValues(res, 'users');
                    this.assignValues(res, 'visitors');
                    this.assignValues(res, 'home_viewed');
                    this.assignValues(res, 'home_viewed_users');
                    this.step = 1;

                    if (proceed) this.fetchStepTwo();

                })
                .catch(this.ShowApiError);
        },
        fetchStepTwo() {
            const payload = {
                ...this.commonPayload({
                    [this.resources.CATEGORY_FEEDBACKS]: true,
                    [this.resources.BOOK_FEEDBACKS]: true,
                    [this.resources.SKILL_FEEDBACKS]: true,
                }),
            };
            this.compare(payload)
                .then(res => {

                    this.assignValues(res, 'category_feedbacks');
                    this.assignValues(res, 'category_feedbacks_users');
                    this.assignValues(res, 'skill_feedbacks');
                    this.assignValues(res, 'skill_feedbacks_users');
                    this.assignValues(res, 'book_feedbacks');
                    this.assignValues(res, 'book_feedbacks_users');

                    this.step = 2;
                    this.fetchStepThree();

                })
                .catch(this.ShowApiError);
        },
        fetchStepThree() {
            const payload = {
                ...this.commonPayload({
                    [this.resources.BOOKS_STARTED]: true,
                    [this.resources.BOOKS_COMPLETED]: true,
                    [this.resources.FAVOURITE_BOOKS]: true,
                }),
            };
            this.compare(payload)
                .then(res => {

                    this.assignValues(res, 'books_started');
                    this.assignValues(res, 'books_started_users');
                    this.assignValues(res, 'books_completed');
                    this.assignValues(res, 'books_completed_users');
                    this.assignValues(res, 'favourite_books');
                    this.assignValues(res, 'favourite_books_users');


                    this.step = 3;
                    this.fetchStepFour();

                })
                .catch(this.ShowApiError);
        },
        fetchStepFour() {
            const payload = {
                ...this.commonPayload({
                    [this.resources.CHECKOUT]: true,
                }),
            };
            this.compare(payload)
                .then(res => {

                    this.assignValues(res, 'checkout_started_users');
                    this.assignValues(res, 'checkout_completed_users');
                    this.assignValues(res, 'checkout_viewed_users');
                    this.assignValues(res, 'checkout_started');
                    this.assignValues(res, 'checkout_completed');
                    this.assignValues(res, 'checkout_viewed');

                    this.result.a.CR_checkout_completed_visitors = this.toPerc(this.result.a.checkout_completed_users / this.partecipants('a'));
                    this.result.b.CR_checkout_completed_visitors = this.toPerc(this.result.b.checkout_completed_users / this.partecipants('b'));

                    this.step = 4;
                    this.fetchStepFive();

                })
                .catch(this.ShowApiError);
        },
        fetchStepFive() {
            const payload = {
                ...this.commonPayload({
                    [this.resources.SUBSCRIPTIONS]: true,
                }),
            };
            this.compare(payload)
                .then(res => {
                    this.assignObject(res, 'subscriptions_per_plan');
                    this.assignObject(res, 'active_subscriptions_per_plan');
                    this.assignValues(res, 'free_trials');
                    this.assignValues(res, 'free_trials_to_paid');
                    this.findTotalLTV();
                    this.step = 5;
                })
                .catch(this.ShowApiError);
        },
        findDelta(a, b) {
            let res = Math.round(10000 * (b - a) / a) / 100;
            if (res > 0) res = '+' + res;
            return res;
        },
        toPerc(val) {
            return Math.round(10000 * val) / 100;
        },
        to2Digits(val) {
            return Math.round(100 * val) / 100;
        },
        findTotalLTV() {
            let ltv = 0;
            if (this.result.a.active_subscriptions_per_plan) {
                this.result.a.active_subscriptions_per_plan.forEach(row => {
                    ltv += row.num * this.planValue(row.plan);
                    this.result.a.TOT_subs_activated += row.num;
                });
            }
            this.result.a.LTV = ltv;
            ltv = 0;
            if (this.result.b.active_subscriptions_per_plan) {
                this.result.b.active_subscriptions_per_plan.forEach(row => {
                    ltv += row.num * this.planValue(row.plan);
                    this.result.b.TOT_subs_activated += row.num;
                });
            }
            if (this.result.a.subscriptions_per_plan) {
                this.result.a.subscriptions_per_plan.forEach(row => {
                    this.result.a.TOT_subs += row.num;
                });
            }
            if (this.result.b.subscriptions_per_plan) {
                this.result.b.subscriptions_per_plan.forEach(row => {
                    this.result.b.TOT_subs += row.num;
                });
            }
            this.result.b.LTV = ltv;
        },
        partecipants(group) {
            let tot = 0;
            if (this.result[group]) {
                if (this.result[group].visitors) tot += this.result[group].visitors;
                if (this.result[group].users) tot += this.result[group].users;
            }
            return tot;
        },
        subsCountBoolFreeTrials(sub_x_plan, free = true){
            if (!(sub_x_plan && sub_x_plan.length)) return 0;
            let count = 0;
            sub_x_plan.forEach(row => {
                if (row.plan){
                    if (free){
                        //Free Trials
                        if (row.plan.trial_period > 0) {
                            count += row.num;
                        }
                    } else {
                        //No trials
                        if (!row.plan.trial_period) {
                            count += row.num;
                        }
                    }
                }
            })
            return count;
        }
    },
};

</script>

<style lang='scss'>
th {
    .p-column-header-content {
        justify-content: center !important;
    }
}

tr {
    .summary {
        background-color: #0b7ad1;
    }
}
</style>
